/* eslint-disable camelcase */

import React from 'react'

const Page = props => {
  return <>
        <main className="main flex-fill" id="top">
            <div className="container" data-layout="container">
            <h5>Kitchensink</h5>
            <div><a href="/app/kitchensink/vendorUpdateBiddingActivityModal">vendorUpdateBiddingActivityModal</a></div>
            <div><a href="/app/kitchensink/vendorUploadBenchmarkModal">vendorUploadBenchmarkModal</a></div>
            <div><a href="/app/kitchensink/vendorUpdateBenchmarkLotModal">vendorUpdateBenchmarkLotModal</a></div>
            <div><a href="/app/kitchensink/vendorUpdateBenchmarkSkuModal">vendorUpdateBenchmarkSkuModal</a></div>
            <div><a href="/app/kitchensink/vendorAnnounceAwardsModal">vendorAnnounceAwardsModal</a></div>
        </div>
      </main>
    </>
}

export default Page
